import React, { useState } from 'react';
import { Button, Layout, List, message, theme } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import ReactMarkdown from 'react-markdown';
import { CodeOutlined } from '@ant-design/icons';
import { HeaderTsx } from '../../../components/HeaderTsx';
import { FooterTsx } from '../../../components/FooterTsx';
import './index.scss'
import { fixBugCodeApi } from '../../../service/Code/api';
import { PandaIcon } from '../../../components/CustomIcon';

const { Content } = Layout;

const FixBug: React.FC = () => {
    const [prompt, setPrompt] = useState<string>('')
    const [list, setList] = useState<any>([])
    const [isLoding, setIsLoding] = useState<boolean>(false)

    const {
        token: { colorSuccessText },
    } = theme.useToken();

    
    const request = () => {
        if(prompt.trim() === ''){
            message.error('请输入内容！')
            return;
        }
        setIsLoding(true)
        fixBugCodeApi({ prompt }).then(
            res => {
                if (res.data.code === '00000') {
                    setList(res.data.data.choices)
                }
                setIsLoding(false)
            }
        ).catch(() => {
            message.error('修复似乎出了些问题～请您重试！')
            setIsLoding(false)
        });

    }

    return (
        <Layout>
            <HeaderTsx />
            <Layout className="site-layout-content">
                <Content >
                    <TextArea
                        showCount
                        maxLength={1000}
                        style={{ height: 320, resize: 'none' }}
                        placeholder="把代码块粘贴进来，不要加其他信息，支持python、js、java等常见编程语言"
                        onChange={(e) => {
                            setPrompt(e.target.value)
                        }}
                    />
                    <br />
                    <Button type="primary" style={{ backgroundColor: colorSuccessText }} icon={<CodeOutlined />} loading={isLoding} onClick={request} >{isLoding ? '正在认真分析中...请您耐心等待～' : '检查并修复Bug'}</Button>
                    <br />
                    <br />
                    <List
                        itemLayout="horizontal"
                        dataSource={list}
                        renderItem={(item: any) => (
                            <List.Item style={{ backgroundColor: 'white', borderRadius: 10 }}>
                                <List.Item.Meta
                                    avatar={<PandaIcon style={{ fontSize: '32px' }} />}
                                    title="中鱼AI"
                                    description={<ReactMarkdown children={item.text} />}
                                />
                            </List.Item>
                        )}
                    />
                </Content>
            </Layout>
            <FooterTsx />
        </Layout>
    );
};

export default FixBug;
