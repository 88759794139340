import { apiRequest } from "../../utils/ApiRequest"

/**
 * 登录二维码
 * @param params 
 * @returns 
 */
export const loginQrcodeApi = () => {
    return apiRequest('/user/login-qrcode', 'POST', {})
}


/**
 * ticket换取登录
 * @param params 
 * @returns 
 */
export const loginTicketApi = (ticket:string) => {
    return apiRequest('/user/login/ticket', 'GET', {ticket})
}
