export const qyyyConfig: State = {
    env: 'prod',
    https: true,
    devApiHost: 'http://localhost:8081',
    dailyApiHost: 'https://api-daily.qiyuyouyun.com',
    prodApiHost: 'https://api.qiyuyouyun.com',
    devPcHost: 'http://localhost:3000',
    dailyPcHost: 'https://chat-daily.id126.com',
    prodPcHost: 'https://chat.id126.com',
    devMobileHost: 'http://localhost:10086',
    dailyMobileHost: 'https://m-daily.chat.id126.com',
    prodMobileHost: 'https://m.chat.id126.com',
}

type State = {
    env?: 'dev' | 'daily' | 'prod',
    https: boolean,
    devApiHost: string;
    dailyApiHost: string;
    prodApiHost: string;
    devPcHost: string;
    dailyPcHost: string;
    prodPcHost: string;
    devMobileHost: string;
    dailyMobileHost: string;
    prodMobileHost: string;
}