import { CodeOutlined, CommentOutlined, FileTextOutlined, RobotOutlined, RocketOutlined, SolutionOutlined, WalletOutlined } from "@ant-design/icons";
import { Alert, Menu, MenuProps } from "antd"
import { Header } from "antd/es/layout/layout"
import { useState } from "react";
import Marquee from "react-fast-marquee";
import { useNavigate } from 'react-router-dom'
import './index.scss'

export const HeaderTsx = () => {
  const [current, setCurrent] = useState('index');
  const navigate = useNavigate()

  const onClick: MenuProps['onClick'] = (e) => {
    console.log('click ', e);
    setCurrent(e.key);
    navigate(e.key)
  };

  const items: MenuProps['items'] = [
    {
      label: '日常闲聊对话',
      key: '/',
      icon: <CommentOutlined />,
    }
    , {
      label: '代码智能辅助',
      key: '/code',
      icon: <CodeOutlined />,
      children: [
        {
          label: '修复代码bug',
          key: '/code/fix-bug',
        },
        {
          label: '解释代码逻辑',
          key: '/code/explain-code',
        },
      ]
    }, {
      label: '学习工具',
      key: '/learn',
      icon: <WalletOutlined />,
      children: [
        {
          label: '生成学习笔记',
          key: '/learn/study-note',
        },
        {
          label: '生成面试问题',
          key: '/learn/interview-question',
        },
      ]
    }, {
      label: '文本智能处理',
      key: '/text',
      icon: <FileTextOutlined />,
      children: [
        {
          type: 'group',
          label: '关键信息提取',
          children: [
            {
              label: '文章摘要提取',
              key: '/text/summary',
            },
            {
              label: '会议记录摘要提取',
              key: '/text/notes-to-summary',
            },
            {
              label: '提取内容关键词',
              key: '/text/keyword',
            },
          ],
        },
        {
          type: 'group',
          label: '自然语言处理',
          children: [

          ],
        },
      ]
    }, {
      label: '内容辅助创作',
      key: '/content',
      icon: <SolutionOutlined />,
      children: [
        {
          type: 'group',
          label: '论文辅助',
          children: [
            {
              label: '开题报告创作',
              key: '/content/thesis-title',
            },
            {
              label: '论文大纲创作',
              key: '/content/thesis-outline',
            },
            {
              label: '论文创作',
              key: '/content/thesis-gene',
            },
          ],
        },
        {
          type: 'group',
          label: '项目工具',
          children: [
            {
              label: '标书创作',
              key: '/content/biding-document',
            },
            {
              label: '项目方案创作',
              key: '/content/project-plan',
            }
          ],
        },
        {
          type: 'group',
          label: '文案策划',
          children: [
            {
              label: '广告文案创作',
              key: '/content/ad',
            },
            {
              label: '短视频脚本创作',
              key: '/content/video-script',
            },
          ],
        },
      ]
    }, {
      label: '起名工具',
      key: '/name',
      icon: <RocketOutlined />,
      children: [
        {
          label: '宝宝起名',
          key: '/name/baby-name',
        },
        {
          label: '产品起名',
          key: '/name/product-name',
        },
      ]
    }
  ]

  return (
    <div>
      <Alert
        banner
        message={
          <Marquee pauseOnHover gradient={false}>
            温馨提示：中鱼AI智能机器人回答的质量和速度与提问的描述相关，描述清晰的提问会获得较高质量的回答。案例：提问“一首诗”与“帮我写一首诗”，后者将获得准确的回答，因为后者是一个准确的需求描述。如果遇到访问高峰期，回答失败，请再次尝试即可，感谢您的使用。
          </Marquee>
        }
      />
      <div className="header-nav">
        {/* <div className="logo"><img src={require('../../assets/logo.png')} width={150} /></div> */}
        <Header style={{ backgroundColor: 'white', paddingLeft: 0, }}>
          <Menu
            onClick={(e) => { onClick(e) }}
            theme='light'
            mode="horizontal"
            items={items}
            selectedKeys={[current]}
          />
        </Header>
      </div>
    </div>
  )
}