import { message } from "antd";
import axios, { AxiosResponse } from "axios";
import { timestamp } from "./DateUtils";
import { getToken, needLogin } from "./GatewayUtils";
import { getApiHost } from "./HostUtils";

/**
 * 统一网络请求入口
 * @param props 
 * @returns 
 */
export const apiRequest = <T>(uri: string,
    method: 'GET' | 'POST',
    params: T) => {
    // 设置超时时间
    axios.defaults.timeout = 120000
    // 获取当前服务端host
    let url = getApiHost() + uri
    if (method === 'GET') {
        let pathParams = '';
        for (const key in params) {
            pathParams = pathParams + key + "=" + params[key] + "&"
        }
        pathParams = pathParams + "timestamp=" + timestamp()
        return new Promise<AxiosResponse<any, any>>((resolve, reject) => {
            axios.get(url + "?" + pathParams, {
                headers: {
                    "carp-token": getToken()
                }
            }).then(
                res => {
                    if (res.status === 200) {
                        if (res.data.code === '00000') {
                            resolve(res)
                        } else if (res.data.code === 'B0203' || res.data.code === 'B0101') {
                            message.error('账号未登录，请登录后再使用～')
                            needLogin()
                            reject('账号未登录')
                        } else {
                            message.error('系统繁忙~')
                            reject('网路请求异常:' + res.data.msg)
                        }
                    } else {
                        message.error('网路请求异常：' + res.status)
                        reject('网路请求异常:' + res.status)
                    }
                }
            )
        })
    } else {
        return new Promise<AxiosResponse<any, any>>((resolve, reject) => {
            axios.post(url, { ...params, timestamp: timestamp() }, {
                headers: {
                    "carp-token": getToken()
                }
            }).then(
                res => {
                    if (res.status === 200) {
                        if (res.data.code === '00000') {
                            resolve(res)
                        } else if (res.data.code === 'B0203' || res.data.code === 'B0101') {
                            message.error('账号未登录，请登录后再使用～')
                            needLogin()
                            reject('账号未登录')
                        } else {
                            message.error('系统繁忙~')
                            reject('网路请求异常:' + res.data.msg)
                        }
                    } else {
                        message.error('网路请求异常：' + res.status)
                        reject('网路请求异常:' + res.status)
                    }
                }
            )
        })
    }
}