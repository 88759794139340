import { Modal, Image, message } from "antd"
import { useEffect, useState } from "react";
import { qyyyConfig } from "../../qyyy.config";
import { loginQrcodeApi, loginTicketApi } from "../../service/User/api";
import { noneNeedLogin, setToken, setUserInfo } from "../../utils/GatewayUtils";
import './index.scss'

type LoginState = {
}

const Login = (props: LoginState) => {
    const [open, setOpen] = useState<boolean>(false)
    const [ticket, setTicket] = useState<string>()
    const [interval, setConInterval] = useState<any>()

    useEffect(() => {
        // 如果需要登录则打开扫码登录框
        if (localStorage.getItem(qyyyConfig.env + ':' + 'need-login')) {
            // ticket不存在的话获取登录二维码
            if (!!!ticket) {
                loginQrcodeApi().then(res => {
                    if (res.data.code === '00000') {
                        console.log(res.data.data)
                        setTicket(res.data.data.ticket)
                        setOpen(true)
                    }
                })
            } else {
                setOpen(true)
            }
        }
    }, [localStorage.getItem(qyyyConfig.env + ':' + 'need-login')])

    useEffect((() => {
        if (open) {
            // 判断是否被扫码登录
            setConInterval(setInterval(() => {
                if (ticket) {
                    loginTicketApi(ticket).then(
                        res => {
                            console.log(res)
                            if (res.data.code === '00000') {
                                if (res.data.data.token) {
                                    setToken(res.data.data.token)
                                    setUserInfo(res.data.data)
                                    // 关闭登录标记
                                    noneNeedLogin()
                                    // 清理ticket
                                    setTicket(undefined)
                                    message.success('登录成功！')
                                    // 关闭窗口
                                    clearInterval(interval)
                                    setOpen(false)
                                    
                                }
                            }
                        }
                    )
                }
            }
                , 1000))

        }
        if (!open) {
            clearInterval(interval)
        }
    }), [open])

    useEffect(() => {

    }, [open])

    return (<><Modal
        open={open}
        footer={null}
        closable={false}
    >
        <div className="login-modal">
            <div className="login-modal-title">您当前未登录，请使用微信扫码快速登录</div>
            <Image
            src={"https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=" + ticket}
        />
        </div>

    </Modal></>)
}

export default Login