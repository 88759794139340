import React, { useState } from 'react';
import { Button, InputNumber, Image, Layout, List, message, Select, Space, theme, Tooltip } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { FileImageOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { HeaderTsx } from '../../components/HeaderTsx';
import { FooterTsx } from '../../components/FooterTsx';
import { imgGentApi } from '../../service/Img/api';
import './index.scss'

const { Content } = Layout;

const Img: React.FC = () => {
    const [prompt, setPrompt] = useState<string>('')
    const [n, setN] = useState<number>(1)
    const [size, setSize] = useState<string>('256x256')
    const [list, setList] = useState<any>([])
    const [isLoding, setIsLoding] = useState<boolean>(false)

    const {
        token: { colorSuccessText, },
    } = theme.useToken();

    const onNumberChange = (value: number | null) => {
        if (value) {
            setN(value)
        }
    }

    const sizeOptions = [
        { value: '256x256', label: '256x256' },
        { value: '512x512', label: '512x512' },
        { value: '1024x1024', label: '1024x1024' }
    ]

    const request = () => {
        if(prompt.trim() === ''){
            message.error('请输入内容！')
            return;
        }
        setIsLoding(true)
        imgGentApi({ prompt, n, size }).then(
            res => {
                if (res.data.code === '00000') {
                    setList(res.data.data.data)
                }
                setIsLoding(false)
            }
        ).catch(() => {
            message.error('思考似乎出了些问题～请您调整一下问题描述重试！')
            setIsLoding(false)
        });

    }

    return (
        <Layout className="layout">
            <HeaderTsx />
            <Layout className="site-layout-content">
                <Content>
                    <Space direction="vertical" size='middle' style={{width:'100%'}}>
                        <TextArea
                            showCount
                            maxLength={200}
                            style={{ height: 70, resize: 'none' }}
                            placeholder="图片主题"
                            onChange={(e) => {
                                setPrompt(e.target.value)
                            }}
                        />
                        <Space>
                            生成数量<InputNumber min={1} max={5} value={n} onChange={onNumberChange} /><Tooltip title="最少生成一张，最多生成5张" color={"green"} key={"green"}>
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </Space>
                        <Space>图片大小<Select
                            defaultValue="256x256"
                            style={{ width: 120 }}
                            options={sizeOptions}
                            onChange={value => setSize(value)}
                        /></Space>

                        <Button type="primary" style={{ backgroundColor: colorSuccessText }} icon={<FileImageOutlined />} loading={isLoding} onClick={request} >{isLoding ? '正在生成...请您耐心等待～' : '开始生成'}</Button>
                        <List
                            itemLayout="horizontal"
                            dataSource={list}
                            renderItem={(item: any) => (
                                <List.Item style={{ backgroundColor: 'white', borderRadius: 10 }}>
                                    <Image src={item.url}/>
                                </List.Item>
                            )}
                        />
                    </Space>
                </Content>
            </Layout>
            <FooterTsx />
        </Layout>
    );
};

export default Img;
