import { apiRequest } from "../../utils/ApiRequest"

/**
 * 产品起名
 * @param params 
 * @returns 
 */
export const productNameApi = <T>(params: T) => {
    return apiRequest('/chat-do/text/product-name', 'POST', params)
}

/**
 * 宝宝起名
 * @param params 
 * @returns 
 */
export const babyNameApi = <T>(params: T) => {
    return apiRequest('/chat-do/text/baby-name', 'POST', params)
}