import React, { useState } from 'react';
import { Button, Layout, List, message, Radio, theme } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import ReactMarkdown from 'react-markdown';
import './index.scss'
import { FooterTsx } from '../../../components/FooterTsx';
import { HeaderTsx } from '../../../components/HeaderTsx';
import { PandaIcon } from '../../../components/CustomIcon';
import { CoffeeOutlined } from '@ant-design/icons';
import { aDApi } from '../../../service/Content/api';

const { Content } = Layout;

const Ad: React.FC = () => {
    const [prompt, setPrompt] = useState<string>('')
    const [scene, setScene] = useState<string>('朋友圈')
    const [list, setList] = useState<any>([])
    const [isLoding, setIsLoding] = useState<boolean>(false)

    const {
        token: { colorSuccessText },
    } = theme.useToken();


    const request = () => {
        if(prompt.trim() === ''){
            message.error('请输入内容！')
            return;
        }
        setIsLoding(true)
        aDApi({ prompt, scene }).then(
            res => {
                if (res.data.code === '00000') {
                    setList(res.data.data.choices)
                }
                setIsLoding(false)
            }
        ).catch(() => {
            message.error('生成似乎出了些问题～请您调整一下产品描述重试！')
            setIsLoding(false)
        });

    }

    return (
        <Layout>
            <HeaderTsx />
            <Layout className="site-layout-content">
                <Content >
                    <div>广告文案使用场景</div>
                    <br />
                    <Radio.Group defaultValue={scene} buttonStyle="solid" onChange={(e) => setScene(e.target.value)}>
                        <Radio.Button value="朋友圈">朋友圈</Radio.Button>
                        <Radio.Button value="群消息">群消息</Radio.Button>
                        <Radio.Button value="微博">微博</Radio.Button>
                        <Radio.Button value="视频">视频</Radio.Button>
                        <Radio.Button value="网站">网站</Radio.Button>
                        <Radio.Button value="促销">促销</Radio.Button>
                        <Radio.Button value="团购">团购</Radio.Button>
                    </Radio.Group>
                    <br />
                    <br />
                    <TextArea
                        showCount
                        maxLength={500}
                        style={{ height: 120, resize: 'none' }}
                        placeholder="输入产品相关描述"
                        onChange={(e) => {
                            setPrompt(e.target.value)
                        }}
                    />
                    <br />
                    <Button type="primary" style={{ backgroundColor: colorSuccessText }} icon={<CoffeeOutlined />} loading={isLoding} onClick={request} >{isLoding ? '正在生成...请您耐心等待～' : '生成广告文案'}</Button>
                    <br />
                    <br />
                    <List
                        itemLayout="horizontal"
                        dataSource={list}
                        renderItem={(item: any) => (
                            <List.Item style={{ backgroundColor: 'white', borderRadius: 10 }}>
                                <List.Item.Meta
                                    avatar={<PandaIcon style={{ fontSize: '32px' }} />}
                                    title="中鱼AI"
                                    description={<ReactMarkdown children={item.text} />}
                                />
                            </List.Item>
                        )}
                    />
                </Content>
            </Layout>
            <FooterTsx />
        </Layout>
    );
};

export default Ad;
