import { apiRequest } from "../../utils/ApiRequest"

/**
 * 广告文案生成
 * @param params 
 * @returns 
 */
export const aDApi = <T>(params: T) => {
    return apiRequest('/chat-do//text/ad', 'POST', params)
}


/**
 * 短视频脚本
 * @param params 
 * @returns 
 */
export const videoScriptApi = <T>(params: T) => {
    return apiRequest('/chat-do/text/video-script', 'POST', params)
}

/**
 * 开题报告
 * @param params 
 * @returns 
 */
export const thesisTitleApi = <T>(params: T) => {
    return apiRequest('/chat-do/text/thesis-title', 'POST', params)
}


/**
 * 论文大纲
 * @param params 
 * @returns 
 */
export const thesisOutlineApi = <T>(params: T) => {
    return apiRequest('/chat-do/text/thesis-outline', 'POST', params)
}

/**
 * 论文写作
 * @param params 
 * @returns 
 */
export const thesisGeneApi = <T>(params: T) => {
    return apiRequest('/chat-do/text/thesis-gene', 'POST', params)
}

/**
 * 标书写作
 * @param params 
 * @returns 
 */
export const bidingDocumentApi = <T>(params: T) => {
    return apiRequest('/chat-do/text/biding-document', 'POST', params)
}

/**
 * 诗词创作
 * @param params 
 * @returns 
 */
export const poemApi = <T>(params: T) => {
    return apiRequest('/chat-do/text/poem', 'POST', params)
}

/**
 * 项目方案
 * @param params 
 * @returns 
 */
export const projectPlanApi = <T>(params: T) => {
    return apiRequest('/chat-do/text/project-plan', 'POST', params)
}

