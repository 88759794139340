import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Img from './pages/Img';
import VConsole from 'vconsole';
import './index.scss';
import Code from './pages/Code/FixBug';
import Summary from './pages/Text/Summary';
import StudyNote from './pages/Learn/StudyNote';
import Keyword from './pages/Text/Keyword';
import ProductName from './pages/Name/ProductName';
import NotesToSummary from './pages/Text/NotesToSummary';
import InterviewQuestion from './pages/Learn/InterviewQuestion';
import Ad from './pages/Content/Ad';
import FixBug from './pages/Code/FixBug';
import ExplainCode from './pages/Code/ExplainCode';
import VideoScript from './pages/Content/VideoScript';
import BidingDocument from './pages/Content/BidingDocument';
import ProjectPlan from './pages/Content/ProjectPlan';
import ThesisGene from './pages/Content/ThesisGene';
import ThesisTitle from './pages/Content/ThesisTitle';
import ThesisOutline from './pages/Content/ThesisOutline';
import BabyName from './pages/Name/BabyName';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// const vConsole = new VConsole()

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="/img" element={<Img />} />
        <Route path="/code/explain-code" element={<ExplainCode />} />
        <Route path="/code/fix-bug" element={<FixBug/>} />
        <Route path="/learn/interview-question" element={<InterviewQuestion />} />
        <Route path="/learn/study-note" element={<StudyNote />} />
        <Route path="/text/notes-to-summary" element={<NotesToSummary />} />
        <Route path="/text/keyword" element={<Keyword />} />
        <Route path="/text/summary" element={<Summary />} />
        <Route path="/content/ad" element={<Ad />} />
        <Route path="/content/video-script" element={<VideoScript />} />
        <Route path="/content/biding-document" element={<BidingDocument />} />
        <Route path="/content/thesis-gene" element={<ThesisGene />} />
        <Route path="/content/project-plan" element={<ProjectPlan />} />
        <Route path="/content/thesis-title" element={<ThesisTitle />} />
        <Route path="/content/thesis-outline" element={<ThesisOutline />} />
        <Route path="/name/baby-name" element={<BabyName />} />
        <Route path="/name/product-name" element={<ProductName />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
