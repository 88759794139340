import { apiRequest } from "../../utils/ApiRequest"

/**
 * 摘要提取接口
 * @param params 
 * @returns 
 */
export const summaryApi = <T>(params: T) => {
    return apiRequest('/chat-do/text/summary', 'POST', params)
}

/**
 * 关键词接口
 * @param params 
 * @returns 
 */
export const keywordApi = <T>(params: T) => {
    return apiRequest('/chat-do/text/keyword', 'POST', params)
}


/**
 * 记录生成摘要
 * @param params 
 * @returns 
 */
export const notesToSummaryApi = <T>(params: T) => {
    return apiRequest('/chat-do/text/notes-to-summary', 'POST', params)
}




