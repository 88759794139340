import { apiRequest } from "../../utils/ApiRequest"

/**
 * 代码类型内容对话接口
 * @param params 
 * @returns 
 */
export const fixBugCodeApi = <T>(params: T) => {
    return apiRequest('/chat-do/code/fix-bug', 'POST', params)
}


/**
 * 代码类型内容对话接口
 * @param params 
 * @returns 
 */
export const explainCodeApi = <T>(params: T) => {
    return apiRequest('/chat-do/code/explain', 'POST', params)
}