import { apiRequest } from "../../utils/ApiRequest"

/**
 * 面试问题生成
 * @param params 
 * @returns 
 */
export const interviewQuestionApi = <T>(params: T) => {
    return apiRequest('/chat-do/text/interview-question', 'POST', params)
}

/**
 * 学习记录生成
 * @param params 
 * @returns 
 */
export const studyNoteApi = <T>(params: T) => {
    return apiRequest('/chat-do/text/study-note', 'POST', params)
}