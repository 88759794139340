import React, { useState } from 'react';
import { Button, Layout, List, message, theme } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import ReactMarkdown from 'react-markdown';
import { SearchOutlined } from '@ant-design/icons';
import { HeaderTsx } from '../../components/HeaderTsx';
import { FooterTsx } from '../../components/FooterTsx';
import { getNextStreamChatApi, streamChatApi } from '../../service/Chat/api';
import './index.scss'
import Card from 'antd/es/card/Card';

const { Content } = Layout;

const Home: React.FC = () => {
    const [question, setQuestion] = useState<string>('')
    const [isLoding, setIsLoding] = useState<boolean>(false)
    const [content, setContent] = useState<string>('')

    let timer: NodeJS.Timeout | null = null;

    const {
        token: { colorSuccessText },
    } = theme.useToken();

    const request = () => {
        if (question.trim() === '') {
            message.error('请输入您的问题！')
            return;
        }
        setIsLoding(true)
        streamChatApi({ prompt: question }).then(
            res => {
                console.log(res)
                if (res.data.code === '00000') {
                    let result = '';
                    timer = setInterval(() => {
                        getNextStreamChatApi({ requestId: res.data.data }).then(
                            ress => {
                                if (ress.data.code === '00000') {

                                    if (ress.data.data === '##stop##') {
                                        // 在这里清除定时器
                                        if (timer) {
                                            clearInterval(timer);
                                            timer = null;
                                            setIsLoding(false)
                                        }
                                    } else {
                                        if (ress.data.data) {
                                            result = result + ress.data.data
                                            setContent(result)
                                        }
                                    }
                                } else {
                                    // 在这里清除定时器
                                    if (timer) {
                                        clearInterval(timer);
                                        timer = null;
                                    }
                                    message.error('系统繁忙！')
                                    setIsLoding(false)
                                }
                            }
                        )
                    }, 100);
                }
            }
        ).catch(() => {
            message.error('思考似乎出了些问题～请您调整一下问题描述重试！')
            setIsLoding(false)
        })

    }

    return (
        <Layout>
            <HeaderTsx />
            <Layout className="site-layout-content">
                <Content >
                    <TextArea
                        showCount
                        maxLength={500}
                        style={{ height: 120, resize: 'none' }}
                        placeholder="咨询内容"
                        onChange={(e) => {
                            setQuestion(e.target.value)
                        }}
                    />
                    <br />
                    <Button type="primary" style={{ backgroundColor: colorSuccessText }} icon={<SearchOutlined />} loading={isLoding} onClick={request} >{isLoding ? '我正在思考...请您耐心等待～' : '向AI提问'}</Button>
                    <br />
                    <br />
                    <Card>
                        {content && <ReactMarkdown children={content} />}
                    </Card>
                </Content>
            </Layout>
            <FooterTsx />
        </Layout>
    );
};

export default Home;
