import { Footer } from "antd/es/layout/layout"
import { useEffect, useState } from "react";
import { qyyyConfig } from "../../qyyy.config";
import { getToken, needLogin } from "../../utils/GatewayUtils";
import { getMobileHost } from "../../utils/HostUtils";
import { isMobile } from "../../utils/ToolsUtils";
import Login from "../Login";
import './index.scss'

export const FooterTsx = () => {
    const [showLogin, setShowLogin] = useState<boolean>(false)

    useEffect(() => {
        if (isMobile()) {
            window.location.href = getMobileHost()
        }
        if (qyyyConfig.https) {
            const href = window.location.href
            const isDevEnv = href.includes("172.16.") || href.includes("192.168.") || href.includes("localhost")
            if (!isDevEnv) {
                if (href.startsWith('http://')) {
                    window.location.href = window.location.href.replace('http://', 'https://')
                }
            }
        }
        // 判断用户是否登录，没有登录则打开登录框
        if(!getToken()){
            needLogin()
        }
    }, [])

    return (<Footer className='footer' style={{ textAlign: 'center', lineHeight: '30px' }}>
        <div className='footer-wx'>
            <img height={90} src={require('../../assets/gzh.png')}></img>
            {/* <img height={90} src={require('../../assets/xcx.png')}></img> */}
        </div>
        <div className='copyright'>
            <div>扫码关注公众号或小程序解锁更多玩法</div>
            <div>功能建议&问题反馈 qiming@carpswim.com</div>
            <div> 特别声明：智能AI回答的结果是算法生成的，不代表本站立场，如有疑问请发邮件与我们联系</div>
            <div>Copyright © 2022~2024 <a href="https://www.carpswim.com/">小川游鱼科技</a> 中鱼智能.FinalAI.V1.3</div>
            <div>起鱼有云提供计算服务</div>
        </div>
        <Login />
    </Footer>)
}