import React, { useState } from 'react';
import { Button, Layout, List, message, theme } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import ReactMarkdown from 'react-markdown';
import { CoffeeOutlined } from '@ant-design/icons';
import './index.scss'
import { FooterTsx } from '../../../components/FooterTsx';
import { HeaderTsx } from '../../../components/HeaderTsx';
import { PandaIcon } from '../../../components/CustomIcon';
import { projectPlanApi } from '../../../service/Content/api';

const { Content } = Layout;

const ProjectPlan: React.FC = () => {
    const [prompt, setPrompt] = useState<string>('')
    const [list, setList] = useState<any>([])
    const [isLoding, setIsLoding] = useState<boolean>(false)



    const {
        token: { colorSuccessText },
    } = theme.useToken();

    const request = () => {
        if(prompt.trim() === ''){
            message.error('请输入内容！')
            return;
        }
        setIsLoding(true)
        projectPlanApi({ prompt }).then(
            res => {
                if (res.data.code === '00000') {
                    setList(res.data.data.choices)
                }
                setIsLoding(false)
            }
        ).catch(() => {
            message.error('似乎出了些问题～请您调整一下描述重试！')
            setIsLoding(false)
        });

    }

    return (
        <Layout>
            <HeaderTsx />
            <Layout className="site-layout-content">
                <Content >
                    <TextArea
                        showCount
                        maxLength={50}
                        style={{ height: 50, width: 390, resize: 'none' }}
                        placeholder="请输入项目名称或主题"
                        onChange={(e) => {
                            setPrompt(e.target.value)
                        }}
                    />
                    <br />
                    <Button type="primary" style={{ backgroundColor: colorSuccessText }} icon={<CoffeeOutlined />} loading={isLoding} onClick={request} >{isLoding ? '正在创作...请您耐心等待～' : '策划项目方案'}</Button>
                    <br />
                    <br />
                    <List
                        itemLayout="horizontal"
                        dataSource={list}
                        renderItem={(item: any) => (
                            <List.Item style={{ backgroundColor: 'white', borderRadius: 10 }}>
                                <List.Item.Meta
                                    avatar={<PandaIcon style={{ fontSize: '32px' }} />}
                                    title="中鱼AI"
                                    description={<ReactMarkdown children={item.text} />}
                                />
                            </List.Item>
                        )}
                    />
                </Content>
            </Layout>
            <FooterTsx />
        </Layout>
    );
};

export default ProjectPlan;
