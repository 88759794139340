import { apiRequest } from "../../utils/ApiRequest"

/**
 * 对话接口
 * @param params 
 * @returns 
 */
export const chatApi = <T>(params: T) => {
    return apiRequest('/chat-do/text/chat', 'POST', params)
}

/**
 * 流式会话
 * @param params 
 * @returns 
 */
export const streamChatApi = <T>(params: T) => {
    return apiRequest('/chat-do/text/stream-chat', 'POST', params)
}


/**
 * 获取流式对话的内容
 * @param params 
 * @returns 
 */
export const getNextStreamChatApi = <T>(params: T) => {
    return apiRequest('/chat-do/text/get-next-stream-chat', 'POST', params)
}