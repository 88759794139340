import { qyyyConfig } from "../qyyy.config"

export const setToken = (token: string) => {
    window.localStorage.setItem(qyyyConfig.env + ':' + 'token', token)
}

export const getToken = () => {
    const token = window.localStorage.getItem(qyyyConfig.env + ':' + 'token')
    if (!token) {
        needLogin()
    }
    return token
}

export const setUserInfo = (user: any) => {
    window.localStorage.setItem(qyyyConfig.env + ':' + 'user', JSON.stringify(user))
}

export const getUserInfo = () => {
    const user = window.localStorage.getItem(qyyyConfig.env + ':' + 'user')
    if (!user) {
        needLogin()
    }
    return user
}

export const needLogin = () => {
    window.localStorage.setItem(qyyyConfig.env + ':' + 'need-login', 'true')
}

export const noneNeedLogin = () => {
    window.localStorage.removeItem(qyyyConfig.env + ':' + 'need-login')
}

export const getNeedLogin = () => {
    return window.localStorage.getItem(qyyyConfig.env + ':' + 'need-login')
}